import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Contract } from '../../interfaces/contract';
import ContractDrawer from './ContractDrawer';
import DataTable from '../share/DataTable';
import DialogComponent from '../share/DialogComponent';
import { GridColDef } from '@mui/x-data-grid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';
import { formatDate } from '../share/dateUtils';

const Contracts: React.FC = () => { 
  const [filter, setFilter] = useState('');
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);
  const [isNew, setIsNew] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentContractForStatusChange, setCurrentContractForStatusChange] =
    useState<Contract | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchContracts();
  }, []);

  const fetchContracts = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(endpoints.contracts);
      setContracts(response.data);
    } catch (error) {
      console.error('Error fetching contracts', error);
    } finally {
      setIsLoading(false)
    }
  };

  const contractColumns: GridColDef[] = [
    { field: 'identifier', headerName: 'ID', width: 200 },
    { field: 'contractName', headerName: 'Nombre' },
    { field: 'startDate', headerName: 'Fecha Inicio', width: 200, align: 'center', headerAlign: 'center' },
    {
      field: 'status',
      headerName: 'Estado',
      width: 150,
      renderCell: (params: any) => (
        <Button onClick={() => handleOpenStatusChangeDialog(params.row)}>
          {params.row.status ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Button>
      ),
      align: 'center',
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 150,
      renderCell: (params: any) => (
        <Button color="primary" onClick={() => handleClickOpen(params.row.id)}>
          Ver
        </Button>
      ),
      align: 'center',
    },
  ]; 

  const contractRows = contracts
    .filter((contract) =>
      contract.contractName.toLowerCase().includes(filter.toLowerCase()),
    )
    .map((contract) => ({
      id: contract._id,
      identifier: contract.identifier,
      contractName: contract.contractName,
      startDate: formatDate(contract.startDate),
      status: contract.status,
      actions: '',
    }));

  const handleClickOpen = (contractId?: string) => {
    if (contractId != null) {
      const fetchContractDetail = async () => {
        try {
          const response = await axiosInstance.get(
            `${endpoints.contracts}/${contractId}`,
          );
          setIsNew(false);
          setSelectedContract(response.data);
          setOpenDrawer(true);
        } catch (error) {
          console.error('Error fetching contract details', error);
        }
      };

      fetchContractDetail();
    } else {
      setIsNew(true);
      setOpenDrawer(true);
      setSelectedContract(null);
    }
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleOpenStatusChangeDialog = (contract: Contract) => {
    setCurrentContractForStatusChange(contract);
    setOpenConfirmDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmStatusChange = async () => {
    if (!currentContractForStatusChange) return;

    try {
      await axiosInstance.patch(
        `${endpoints.contracts}/${currentContractForStatusChange._id}/status`,
        {
          status: !currentContractForStatusChange.status,
        },
      );
      fetchContracts();
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating contract status', error);
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
      <DataTable 
        columns={contractColumns} 
        rows={contractRows} 
        pageSize={10} 
        onAddClick={() => handleClickOpen()} 
        title='Contratos' 
        buttonText='Nuevo Contrato' />
      )}
      <ContractDrawer
        open={openDrawer}
        onClose={handleClose}
        item={selectedContract}
        isNew={isNew}
      />
      <DialogComponent
        open={openConfirmDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmStatusChange}
        title="Cambiar estado del contrato"
        children="¿Estás seguro de que deseas cambiar el estado de este contrato?"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contracts;
