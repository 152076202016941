import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import React from "react";
import { ThemeProvider } from "./context/ThemeContext";
import { createRoot } from 'react-dom/client';

const container = document.getElementById('app');

if (container) {
  const root = createRoot(container);

  root.render(
    <AuthProvider>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </AuthProvider>
  );
} else {
  console.error("Failed to find the app root element");
}
