import { AccountCircle, ExitToApp, Notifications } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  Switch,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";

import React from "react";
import { useAuth } from "../../context/AuthContext";
import { useTheme } from "../../context/ThemeContext";

const Topbar = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { logout } = useAuth();

  return (
    <AppBar position="fixed" sx={{ zIndex: 1300, height: 64 }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Roomvice
        </Typography>
        <Switch checked={isDarkMode} onChange={toggleTheme} />
        <Tooltip title="Notifications">
          <IconButton color="inherit">
            <Notifications />
          </IconButton>
        </Tooltip>
        <Tooltip title="Profile">
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Tooltip title="Logout">
          <IconButton color="inherit" onClick={logout}>
            <ExitToApp />
          </IconButton>
        </Tooltip>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
