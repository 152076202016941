import {
  Apps,
  Assignment,
  Dashboard,
  People,
  Settings,
} from "@mui/icons-material";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { Link } from "react-router-dom";
import React from "react";

const Sidebar = () => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 3,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        zIndex: 1200,
      }}
    >
      <List sx={{ mt: 8 }}>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        <ListItem button component={Link} to="/contracts">
          <ListItemIcon>
            <Assignment />
          </ListItemIcon>
          <ListItemText primary="Contratos" />
        </ListItem>
        <ListItem button component={Link} to="/applications">
          <ListItemIcon>
            <Apps />
          </ListItemIcon>
          <ListItemText primary="Aplicaciones" />
        </ListItem>
        <ListItem button component={Link} to="/users">
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary="Usuarios" />
        </ListItem>
        <ListItem button component={Link} to="/settings">
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          <ListItemText primary="Configuraciones" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Sidebar;
