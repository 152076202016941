import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { DrawerProps } from '../share/interface/DrawerProps';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

// const titles = ['Mr.', 'Ms.', 'Mrs.', 'Miss'];

const AddRoom: React.FC<DrawerProps> = ({ open, onClose, item }) => {
  const [roomData, setRoomData] = useState({
    deviceIdentifier: '',
    deviceId: '123456',
    admin: true,
    placeContentId: item.placeContent[0]._id,
    guestContent: {
      title: '',
      firstName: '',
      lastName: '',
      greetings: '',
      channelList: '',
      welcomeVideoUrl: '',
      slogan: 'Esperamos que disfrute su instancia',
      textColor: '#000000',
    },
    roomApplicationConfig: [],
  });

  const [applications, setApplications] = useState<any[]>([]);

  useEffect(() => {
    const fetchApplicationConfigs = async () => {
      const response = await axiosInstance.get(endpoints.applications);
      setApplications(
        response.data.map((app: any) => ({
          ...app,
          selected: false,
        })),
      );
    };
    fetchApplicationConfigs();
  }, []);

  const handleChange = (event: any) => {
    const { name, value, checked, type } = event.target;
    if (name.startsWith('guestContent.')) {
      const contentName = name.split('.')[1];
      setRoomData((prev) => ({
        ...prev,
        guestContent: {
          ...prev.guestContent,
          [contentName]: value,
        },
      }));
    } else if (name === 'selectAll') {
      const newApps = applications.map((app) => ({
        ...app,
        selected: checked,
      }));
      setApplications(newApps);
    } else if (name.startsWith('selectApp')) {
      const appId = value;
      const newApps = applications.map((app) =>
        app.id === appId ? { ...app, selected: !app.selected } : app,
      );
      setApplications(newApps);
    } else if (type === 'checkbox') {
      setRoomData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setRoomData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    const roomApplicationConfigs = applications
      .filter((app) => app.selected)
      .map((app) => ({ applicationConfigId: app._id, status: true }));

    const postData = {
      ...roomData,
      roomApplicationConfig: roomApplicationConfigs,
    };

    try {
      await axiosInstance.post(endpoints.room, postData);
      onClose(true);
    } catch (error) {
      console.error('Failed to add new room', error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: '60vw', padding: 2 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Nueva Habitacion
        </Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Informacion del dispositivo
            </Typography>
            <TextField
              label="Device Identifier"
              variant="outlined"
              fullWidth
              name="deviceIdentifier"
              value={roomData.deviceIdentifier}
              onChange={handleChange}
              sx={{ mb: 5 }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={roomData.admin}
                  onChange={handleChange}
                  name="admin"
                />
              }
              label="Admin Access"
              sx={{ mb: 5 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Informacion del huesped
            </Typography>
            <FormControl fullWidth sx={{ mb: 2 }}>
              {/* <InputLabel>Titulo</InputLabel>
              <Select
                label="Titulo"
                name="guestContent.title"
                value={roomData.guestContent.title}
                onChange={handleChange}
              >
                {titles.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select> */}
              <TextField
                label="Titulo"
                variant="outlined"
                fullWidth
                name="guestContent.title"
                value={roomData.guestContent.title}
                onChange={handleChange}
                sx={{ mb: 2 }}
              />
            </FormControl>
            <TextField
              label="First Name"
              variant="outlined"
              fullWidth
              name="guestContent.firstName"
              value={roomData.guestContent.firstName}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Apellido"
              variant="outlined"
              fullWidth
              name="guestContent.lastName"
              value={roomData.guestContent.lastName}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="Saludo"
              variant="outlined"
              fullWidth
              name="guestContent.greetings"
              value={roomData.guestContent.greetings}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="URL del video de bienvenida"
              variant="outlined"
              fullWidth
              name="guestContent.welcomeVideoUrl"
              value={roomData.guestContent.welcomeVideoUrl}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
            <TextField
              label="URL de la lista de canales"
              variant="outlined"
              fullWidth
              name="guestContent.channelList"
              value={roomData.guestContent.channelList}
              onChange={handleChange}
              sx={{ mb: 2 }}
            />
          </Grid>
        </Grid>
        <Grid>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      indeterminate={
                        applications.some((app) => app.selected) &&
                        applications.some((app) => !app.selected)
                      }
                      checked={applications.every((app) => app.selected)}
                      onChange={handleChange}
                      name="selectAll"
                    />
                  </TableCell>

                  <TableCell>ID</TableCell>
                  <TableCell>Nombre aplicacion</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {applications.map((app) => (
                  <TableRow
                    key={app.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={app.selected}
                        onChange={handleChange}
                        name="selectApp"
                        value={app.id}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {app.id}
                    </TableCell>
                    <TableCell>{app.name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Guardar
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => onClose(false)}
            sx={{ ml: 2 }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default AddRoom;
