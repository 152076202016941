import { Box, Button, Grid, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';

import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

interface PlaceContentProps {
  placeContent: {
    _id: string;
    name: string;
    locale: string;
    city: string;
    zipCode: string;
    logoUrl: string;
    backgroundUrl: string;
    timeZone: string;
    contract: string;
  };
  isNew: boolean;
}

const PlaceContent: React.FC<PlaceContentProps> = ({ placeContent, isNew }) => {
  const [isEditable, setIsEditable] = useState(isNew);
  const [formData, setFormData] = useState(placeContent);
  const [initialData, setInitialData] = useState(placeContent);

  useEffect(() => {
    setInitialData(JSON.parse(JSON.stringify(placeContent)));
  }, [placeContent]);

  const handleEditToggle = () => {
    if (isEditable && !isNew) {
      updatePlaceContent();
    } else if (isNew) {
      createNewPlaceContent();
    }
    setIsEditable(!isEditable);
  };

  const updatePlaceContent = async () => {
    const contractData = {
      backgroundUrl: formData.backgroundUrl,
      city: formData.city,
      contract: formData.contract,
      locale: formData.locale,
      logoUrl: formData.logoUrl,
      name: formData.name,
      timeZone: formData.timeZone,
      zipCode: formData.zipCode,

    };
    try {
      await axiosInstance.patch(
        `${endpoints.placeContent}/${placeContent._id}`,
        contractData,
      );
    } catch (error) {
      console.error('Failed to update contract details:', error);
      alert('Failed to update contract details.');
    }
  };

  function createNewPlaceContent() {
    throw new Error('Function not implemented.');
  }
  

  const cancelEdit = () => {
    setFormData(initialData);
    setIsEditable(false);
  };

  return (
    <Box padding={3}>
        <Grid container spacing={5} key={placeContent._id}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nombre Hotel"
              variant="outlined"
              fullWidth
              name="name"
              value={placeContent.name}
              // onChange={(e) => handleChange(e, placeContent)}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
            <TextField
              label="Locale"
              variant="outlined"
              fullWidth
              name="locale"
              value={placeContent.locale}
              // onChange={(e) => handleChange(e, index)}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
            <TextField
              label="Ciudad"
              variant="outlined"
              fullWidth
              name="city"
              value={placeContent.city}
              // onChange={(e) => handleChange(e, index)}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
            <TextField
              label="Codigo ZIP"
              variant="outlined"
              fullWidth
              name="zipCode"
              value={placeContent.zipCode}
              // onChange={(e) => handleChange(e, index)}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
            <TextField
              label="Time Zone"
              variant="outlined"
              fullWidth
              name="timeZone"
              value={placeContent.timeZone}
              // onChange={(e) => handleChange(e, index)}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={placeContent.logoUrl}
              alt="Logo"
              style={{ width: 200, height: 'auto', marginBottom: 8 }}
            />
            <img
              src={placeContent.backgroundUrl}
              alt="Background"
              style={{ width: 200, height: 'auto' }}
            />
          </Grid>
        </Grid>
      <Box mt={2} display="flex" justifyContent="flex-end">
        {isEditable && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={cancelEdit}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleEditToggle}>
          {isEditable ? 'Guardar' : 'Editar'}
        </Button>
      </Box>
    </Box>
  );
};

export default PlaceContent;
