export const API_BASE_URL = process.env.REACT_APP_API_URL;

const endpoints = {
  applications: `${API_BASE_URL}/application-config`,
  auth: `${API_BASE_URL}/auth`,
  contracts: `${API_BASE_URL}/contracts`,
  contractsWithDetails: `${API_BASE_URL}/contracts/withDetails`,
  login: `${API_BASE_URL}/auth/login`,
  placeContent: `${API_BASE_URL}/placeContent`,
  register: `${API_BASE_URL}/register`,
  room: `${API_BASE_URL}/room`,
  roomApplicationConfig: `${API_BASE_URL}/room-application-confi`,
  user: `${API_BASE_URL}/users`,
  validateCode: `${API_BASE_URL}/auth/validate-code`,
  validateToken: `${API_BASE_URL}/auth/validate-token`,

};

export default endpoints;