import { Box, Drawer, Tab, Tabs, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import ContractDetail from '../ContractDetails/ContractDetail';
import { DrawerProps } from '../share/interface/DrawerProps';
import PlaceContent from '../PlaceContent/PlaceContent';
import Room from '../Room/Room';

const ContractDrawer: React.FC<DrawerProps> = ({
  open,
  onClose,
  item,
  isNew,
}) => {
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (isNew) {
      setTabValue(0);
    }
  }, [isNew]);

  const handleChangeTab = (
    event: any,
    newValue: React.SetStateAction<number>,
  ) => {
    setTabValue(newValue);
  };

  const getTabContent = () => {
    switch (tabValue) {
      case 0:
        return <ContractDetail contractDetail={item} isNew={isNew || false} />;
      case 1:
        return <PlaceContent placeContent={item.placeContent[0]} isNew={isNew || false} />;
      case 2:
        return <Room contract={item} rooms={item.rooms} isNew={isNew || false} />;
      default:
        return <Typography>Error: Tab index out of range.</Typography>;
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Typography
        variant="h4"
        sx={{ marginTop: 2, marginBottom: 2, padding: 2 }}
      >
        {item ? item.contractName : isNew ? 'Nuevo' : 'Loading...'}
      </Typography>
      <Box sx={{ width: '80vw', padding: 2 }}>
        <Tabs value={tabValue} onChange={handleChangeTab} variant="fullWidth">
          {<Tab label="Detalle" hidden={!isNew} />}
          {<Tab label="Place Content" hidden={!isNew} />}
          {<Tab label="Rooms" hidden={!isNew} />}
        </Tabs>
        <Box sx={{ marginTop: 2 }}>{getTabContent()}</Box>
      </Box>
    </Drawer>
  );
};

export default ContractDrawer;
