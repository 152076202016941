import { AuthProvider, useAuth } from "./context/AuthContext";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";

import Applications from "./components/Applications/Applications";
import Contracts from "./components/Contracts/Contracts";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Auths/Login";
import React from "react";
import Register from "./components/Auths/Register";
import RequireAuth from "./context/RequireContext";
import Sidebar from "./components/Layouts/Sidebar";
import { ThemeProvider } from "./context/ThemeContext";
import Topbar from "./components/Layouts/Topbar";
import Users from "./components/Users/User";

const App = () => {
  return (
    <AuthProvider>
      <ThemeProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/*"
              element={
                <RequireAuth>
                  <Layout />
                </RequireAuth>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </AuthProvider>
  );
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return children;
};

const Layout = () => {
  return (
    <div style={{ display: "flex" }}>
      <Sidebar />
      <div style={{ flexGrow: 1, marginLeft: 240 }}>
        <Topbar />
        <div style={{ marginTop: 64, padding: 16 }}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/contracts" element={<Contracts />} />
            <Route path="/applications" element={<Applications />} />
            <Route path="/users" element={<Users /> } />
            <Route path="/settings" element={<div>Configuraciones</div>} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
