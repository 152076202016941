import { Bar, BarChart, Cell, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Card, CardContent, Typography } from '@mui/material';

import React from 'react';

interface StatisticCardProps {
  title: string;
  value: string;
  data: Array<{ name: string; value: number }>;
  color: string;
  bgColor: string;
  chartType: 'line' | 'bar' | 'pie';
}

const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: any }) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)', color: 'white', padding: '5px', borderRadius: '3px' }}>
        <p>{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const StatisticCard: React.FC<StatisticCardProps> = ({ title, value, data, color, bgColor, chartType }) => {
  return (
    <Card sx={{ minWidth: 275, backgroundColor: bgColor, padding: '10px' }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <ResponsiveContainer width="100%" height={80}>
          {chartType === 'line' ? (
            <LineChart data={data}>
              <Line type="monotone" dataKey="value" stroke={color} strokeWidth={2} />
              <Tooltip content={<CustomTooltip />} />
            </LineChart>
          ) : chartType === 'pie' ? (
            <PieChart>
              <Pie data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={30} fill={color}>
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.name === 'Devices' ? color : '#FFBB28'} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          ) : (
            <BarChart data={data}>
              <Bar dataKey="value" fill={color} style={{ filter: 'brightness(1)' }} />
              <Tooltip content={<CustomTooltip />} />
            </BarChart>
          )}
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
