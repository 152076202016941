import { Box, Button, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DataTable from '../share/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { User } from '../share/interface/user'
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

const Users: React.FC = () => {

  const [filter, setFilter] = useState('');
  const [users, setUsers] = useState<User[]>([]);
  const [userFiltered, setUserFiltered] = useState<User>();
  const [isLoading, setIsLoading] = useState(true)

  const userColumns: GridColDef[] = [
    { field: 'username', headerName: 'Usuario', width: 150 },
    { field: 'name', headerName: 'Nombre'},
    { field: 'email', headerName: 'E-mail' },
    { field: 'role', headerName: 'Rol', width: 150 },
    { field: 'status', headerName: 'Estado', width: 150,
      renderCell: (params: any) => (
        <Button>
          { params.row.status ? (
              <CheckCircleOutlineIcon color='success' />
            ) : (
              <RemoveCircleOutlineIcon color='error' />
            )
          }
        </Button>
      ),
      align: 'center'
    }
  ];

  const userRows = users
    .filter((user) => 
      user.username.toLowerCase().includes(filter.toLowerCase()),
    )
    .map((user) => ({
      id: user._id,
      username: user.username,
      name: `${user.firstname} ${user.lastname}`,
      email: user.email,
      role: user.role,
      status: user.status,
    }));

    const handleClickOpen = (userId?: string) => {
      if (userId != null) {
        const fetchUserDetails = async () => {
          try {
            const userFilter = users.filter(x => x._id = userId)
            setUserFiltered(userFilter[0])
            console.log(userFiltered)
          } catch (error) {
            console.error('Error fetching user details', error);
          }
        }
      }
    }

    useEffect(() => {
      const fetchUsers = async () => {
        setIsLoading(true)
        try {
          const response = await axiosInstance.get(endpoints.user);
          setUsers(response.data)
        } catch (error) {
          console.error(`Error fetching users`, error);
        } finally {
          setIsLoading(false)
        }
      };
      fetchUsers()
    }, []);

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
      <DataTable 
        columns={userColumns} 
        rows={userRows} 
        pageSize={10} 
        onAddClick={() => handleClickOpen()}
        title='Usuarios' 
        buttonText='Nuevo usuario' />
      )}
    </Box>
  );

}

export default Users;