import {
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';
import { format } from 'date-fns';

interface ContractDetailData {
  _id: string;
  devices: number;
  invoicePeriod: string;
  contractLength: number;
}

interface Contract {
  _id: string;
  identifier: string;
  contractName: string;
  status: boolean;
  startDate: string;
  contractDetail?: ContractDetailData;
}

interface ContractDetailProps {
  contractDetail?: Contract;
  isNew: boolean;
}

interface FormData {
  id: string;
  identifier: string;
  contractName: string;
  status: boolean;
  startDate: string;
  devices: number;
  invoicePeriod: string;
  contractLength: number;
}

const ContractDetail: React.FC<ContractDetailProps> = ({
  contractDetail,
  isNew,
}) => {
  const initialDetail: FormData = useMemo(() => ({
    id: '',
    identifier: '',
    contractName: '',
    status: false,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    devices: 0,
    invoicePeriod: 'Monthly',
    contractLength: 12,
  }), []);

  const safeDetail = contractDetail || {
    _id: '',
    identifier: '',
    contractName: '',
    status: false,
    startDate: format(new Date(), 'yyyy-MM-dd'),
    contractDetail: {
      _id: '',
      devices: 0,
      invoicePeriod: 'Monthly',
      contractLength: 12,
    },
  };

  const [isEditable, setIsEditable] = useState(isNew);
  const [formData, setFormData] = useState<FormData>(() => ({
    id: safeDetail._id,
    identifier: safeDetail.identifier,
    contractName: safeDetail.contractName,
    status: safeDetail.status,
    startDate: format(new Date(safeDetail.startDate), 'yyyy-MM-dd'),
    devices: safeDetail.contractDetail?.devices || 0,
    invoicePeriod: safeDetail.contractDetail?.invoicePeriod || 'Monthly',
    contractLength: safeDetail.contractDetail?.contractLength || 12,
  }));

  useEffect(() => {
    if (!isNew && contractDetail) {
      setFormData({
        id: contractDetail._id,
        identifier: contractDetail.identifier,
        contractName: contractDetail.contractName,
        status: contractDetail.status,
        startDate: format(new Date(contractDetail.startDate), 'yyyy-MM-dd'),
        devices: contractDetail.contractDetail?.devices || 0,
        invoicePeriod: contractDetail.contractDetail?.invoicePeriod || 'Monthly',
        contractLength: contractDetail.contractDetail?.contractLength || 12,
      });
    }
  }, [isNew, contractDetail]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleEditToggle = async () => {
    if (isEditable && !isNew) {
      await updateContractDetails();
    } else if (isNew) {
      await createNewContract();
    }
    setIsEditable(!isEditable);
  };

  const cancelEdit = () => {
    if (!isNew && contractDetail) {
      setFormData({
        id: contractDetail._id,
        identifier: contractDetail.identifier,
        contractName: contractDetail.contractName,
        status: contractDetail.status,
        startDate: format(new Date(contractDetail.startDate), 'yyyy-MM-dd'),
        devices: contractDetail.contractDetail?.devices || 0,
        invoicePeriod: contractDetail.contractDetail?.invoicePeriod || 'Monthly',
        contractLength: contractDetail.contractDetail?.contractLength || 12,
      });
    } else {
      setFormData(initialDetail);
    }
    setIsEditable(false);
  };

  const updateContractDetails = async () => {
    const contractData = {
      identifier: formData.identifier,
      contractName: formData.contractName,
      status: formData.status,
      startDate: formData.startDate,
      contractDetails: {
        _id: safeDetail.contractDetail?._id,
        devices: formData.devices,
        invoicePeriod: formData.invoicePeriod,
        contractLength: formData.contractLength,
      },
    };
    try {
      await axiosInstance.patch(
        `${endpoints.contracts}/${safeDetail._id}/detail`,
        contractData,
      );
    } catch (error) {
      console.error('Failed to update contract details:', error);
      alert('Failed to update contract details.');
    }
  };

  const createNewContract = async () => {
    const contractData = {
      identifier: formData.identifier,
      contractName: formData.contractName,
      status: formData.status,
      startDate: formData.startDate,
      contractDetails: {
        devices: formData.devices,
        invoicePeriod: formData.invoicePeriod,
        contractLength: formData.contractLength,
      },
    };
    try {
      await axiosInstance.post(endpoints.contracts, contractData);
    } catch (error) {
      console.error('Failed to create new contract:', error);
      alert('Failed to create new contract.');
    }
  };

  return (
    <Box padding={3}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Información del contrato
          </Typography>
          <TextField
            label="Identificador de contrato"
            variant="outlined"
            fullWidth
            name="identifier"
            value={formData.identifier}
            onChange={handleChange}
            disabled={!isNew}
            sx={{ mb: 5 }}
          />
          <TextField
            label="Nombre de contrato"
            variant="outlined"
            fullWidth
            name="contractName"
            value={formData.contractName}
            onChange={handleChange}
            disabled={!isNew}
            sx={{ mb: 5 }}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={formData.status}
                  onChange={handleChange}
                  name="status"
                />
              }
              label={formData.status ? 'Activo' : 'Inactivo'}
              disabled={!isEditable}
              sx={{ mb: 5 }}
            />
          </FormGroup>
          <TextField
            label="Fecha de inicio"
            type="date"
            fullWidth
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            disabled={!isEditable}
            InputLabelProps={{ shrink: true }}
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Detalle del contrato
          </Typography>
          <TextField
            label="Máximo de dispositivos"
            type="number"
            fullWidth
            name="devices"
            value={formData.devices}
            onChange={handleChange}
            disabled={!isEditable}
            sx={{ mb: 2 }}
          />
          <TextField
            select
            label="Periodo de cobro"
            fullWidth
            name="invoicePeriod"
            value={formData.invoicePeriod}
            onChange={handleChange}
            disabled={!isEditable}
            sx={{ mb: 2 }}
          >
            <MenuItem value="Monthly">Mensual</MenuItem>
            <MenuItem value="Annual">Anual</MenuItem>
          </TextField>
          <TextField
            select
            label="Longitud de contrato (meses)"
            fullWidth
            name="contractLength"
            value={formData.contractLength}
            onChange={handleChange}
            disabled={!isEditable}
            sx={{ mb: 2 }}
          >
            {[12, 24, 36, 48, 60].map((length) => (
              <MenuItem key={length} value={length}>
                {length}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <Box mt={2} display="flex" justifyContent="flex-end">
        {isEditable && (
          <Button
            variant="outlined"
            color="secondary"
            onClick={cancelEdit}
            sx={{ mr: 2 }}
          >
            Cancelar
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={handleEditToggle}>
          {isEditable ? 'Guardar' : 'Editar'}
        </Button>
      </Box>
    </Box>
  );
};

export default ContractDetail;
