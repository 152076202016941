import { Box, Button, TextField, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';

export interface DataTableProps {
  columns: GridColDef[];
  rows: any[];
  pageSize?: number;
  onAddClick?: () => void;
  title?: string;
  buttonText?: string;
}

const DataTable: React.FC<DataTableProps> = ({ columns, rows, pageSize = 5, onAddClick, title, buttonText }) => {
  const [filterText, setFilterText] = useState('');

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterText(event.target.value);
  };

  const filteredRows = rows.filter((row) =>
    Object.values(row).some((value) =>
      String(value).toLowerCase().includes(filterText.toLowerCase())
    )
  );

  return (
    <Box>
      {title && (
        <Typography variant="h6" sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      {onAddClick && (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <TextField
          label="Buscar"
          variant="outlined"
          value={filterText}
          onChange={handleFilterChange}
          fullWidth
          sx={{ mr: 2}}
        />
        {onAddClick && (
          <Button variant="contained" color="primary" onClick={onAddClick}>
            {buttonText}
          </Button>
        )}
      </Box>)}
      <div style={{ height: '100%', width: '100%' }}>
      <DataGrid
          rows={filteredRows}
          columns={columns.map((column) => ({
            ...column,
            flex: column.width ? undefined : 1,
            headerAlign: column.align || 'left', 
            cellClassName: column.align || 'left',
          }))}
          initialState={{
            pagination: {
              paginationModel: { pageSize: pageSize },
            },
          }}
          pageSizeOptions={[5, 10, 20, 50, 100]} 
          disableRowSelectionOnClick
        />
      </div>
    </Box>
  );
};

export default DataTable;
