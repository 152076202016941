import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

import { DrawerProps } from '../share/interface/DrawerProps';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

const RoomDrawer: React.FC<DrawerProps> = ({ open, onClose, item }) => {
  const [isAdmin, setIsAdmin] = useState(item?.data.admin || false);

  if (!item) {
    return (
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box sx={{ width: '60vw', padding: 2 }}>
          <Typography variant="h6" sx={{ padding: 2 }}>
            Loading room details...
          </Typography>
        </Box>
      </Drawer>
    );
  }

  const handleActiveChange = async (app: any, checked: boolean) => {
    try {
      await axiosInstance.patch(
        `${endpoints.roomApplicationConfig}/${app._id}`,
        { status: checked },
      );
    } catch (error) {
      console.error('Error updating room application config', error);
    }
  };

  const handleAdminChange = async (event: any) => {
    setIsAdmin(event.target.checked);
    try {
      await axiosInstance.patch(`${endpoints.room}/${item.data._id}`, {
        admin: !isAdmin,
      });
    } catch (error) {
      console.error('Error updating the room admin access', error);
    }
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ width: '60vw', padding: 2 }}>
        <Typography
          variant="h4"
          sx={{ marginTop: 2, marginBottom: 2, padding: 2 }}
        >
          {item ? item.data.deviceIdentifier : 'Loading...'}
        </Typography>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Basic Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>Device ID: {item.deviceId}</Typography>
            <FormControlLabel
              control={
                <Switch
                  checked={isAdmin}
                  onChange={handleAdminChange}
                  name="Admin Access"
                />
              }
              label={isAdmin ? 'Yes' : 'No'}
            />
            <Typography>Place: {item.data.placeContent?.name}</Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Guest Content</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {item.data.guestContent &&
              item.data.guestContent.map((content: any, index: number) => (
                <Box key={index}>
                  <Typography>Titulo: {content.title}</Typography>
                  <Typography>
                    Nombre: {content.firstName} {content.lastName}
                  </Typography>
                  <Typography>Saludo: {content.greetings}</Typography>
                  <Typography>Channel list: {content.channelList}</Typography>
                  <Typography>
                    Welcome video: {content.welcomeVideoUrl}
                  </Typography>
                </Box>
              ))}
          </AccordionDetails>
        </Accordion>

        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Application Configurations</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Order</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Active</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.data.roomApplicationConfig &&
                    item.data.roomApplicationConfig.map(
                      (app: any, index: number) => (
                        <TableRow key={index}>
                          <TableCell>{app.applicationConfigId.order}</TableCell>
                          <TableCell component="th" scope="row">
                            {app.applicationConfigId.name}
                          </TableCell>
                          <TableCell>
                            {app.applicationConfigId.action}
                          </TableCell>
                          <TableCell>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={app.status}
                                  onChange={(e) =>
                                    handleActiveChange(app, e.target.checked)
                                  }
                                  name="active"
                                />
                              }
                              label={app.status ? 'Sí' : 'No'}
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Drawer>
  );
};

export default RoomDrawer;
