import {
    Alert,
    Box,
    Button,
    Snackbar,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import AddRoom from './AddRoom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DataTable from '../share/DataTable';
import DialogComponent from '../share/DialogComponent';
import { GridColDef } from '@mui/x-data-grid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import RoomDrawer from './RoomDrawer';
import { RoomInterface } from '../share/interface/room';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

interface RoomProps {
  contract: any;
  rooms: {
    _id: string;
    deviceIdentifier: string;
    deviceId: string;
    admin: boolean;
    placeContent: string;
  }[];
  isNew: boolean;
}

const Room: React.FC<RoomProps> = ({ contract, rooms = [], isNew }) => {
  const [filter, setFilter] = useState('');
  const [selectedRoom, setSelectedRoom] = useState<any>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openAddDrawer, setAddOpenDrawer] = useState(false);
  const [roomList, setRoomList] = useState(rooms);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [currentRoomForStatusChange, setCurrentRoomForStatusChange] = useState<RoomInterface | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [isEditable, setIsEditable] = useState(isNew);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      const response = await axiosInstance.get(
        `${endpoints.room}/placeContent/${rooms[0].placeContent}`,
      );
      setRoomList(response.data);
    } catch (error) {
      console.error('Error fetching rooms', error);
    }
  };

  const filteredRooms = roomList.filter(
    (room) =>
      room.deviceIdentifier.toLowerCase().includes(filter.toLowerCase()) ||
      room.deviceId.includes(filter),
  );

  const roomColumns: GridColDef[] = [
    { field: 'deviceIdentifier', headerName: 'Identifier' },
    { field: 'deviceId', headerName: 'Firma dispositivo' },
    { field: 'admin', headerName: 'Modo admin', width: 200, align: 'center', headerAlign: 'center',
      renderCell: (params: any) => (
        <Button onClick={() => handleOpenStatusChangeDialog(params.row)}>
          {params.row.status ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Button>
      )
    },
    { field: 'actions', headerName: 'Acciones', width: 200, align: 'center', headerAlign: 'center' },
  ]; 

  const handleOpenStatusChangeDialog = (room: RoomInterface) => {
    setCurrentRoomForStatusChange(contract);
    setOpenConfirmDialog(true);
  };

  const roomRows = rooms
    .filter((room) =>
      room.deviceIdentifier.toLowerCase().includes(filter.toLowerCase()),
    )
    .map((room) => ({
      id: room._id,
      deviceIdentifier: room.deviceIdentifier,
      deviceId: room.deviceId,
      admin: room.admin,
      actions: ''
    }));

  const handleClickOpen = (roomId: string) => {
    const fetchContractDetail = async () => {
      try {
        const response = await axiosInstance.get(
          `${endpoints.room}/room/${roomId}`,
        );
        setSelectedRoom(response.data);
        setOpenDrawer(true);
      } catch (error) {
        console.error('Error fetching contract details', error);
      }
    };

    fetchContractDetail();
  };

  const handleClose = () => {
    setOpenDrawer(false);
  };

  const handleCloseAddRoom = (didAdd: boolean) => {
    setAddOpenDrawer(false);
    if (didAdd) {
      const fetchRooms = async () => {
        const response = await axiosInstance.get(endpoints.room);
        setRoomList(response.data);
      };
      fetchRooms();
      setSnackbarMessage('Room added successfully!');
      setSnackbarOpen(true);
    }
  };

  const handleAddRoom = () => {
    setAddOpenDrawer(true);
  };

  const handleCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleConfirmStatusChange = async () => {
    if (!currentRoomForStatusChange) return;

    try {
      await axiosInstance.patch(
        `${endpoints.room}/${currentRoomForStatusChange._id}/status`,
        {
          status: !currentRoomForStatusChange.admin,
        },
      );
      handleCloseDialog();
    } catch (error) {
      console.error('Error updating contract status', error);
    }
  };

  return (
    <Box>
      <DataTable
        columns={roomColumns}
        rows={roomRows}
        pageSize={10}
        onAddClick={() => handleAddRoom} 
        title='Room' 
        buttonText='Nuevo Room'
      />
      <RoomDrawer open={openDrawer} onClose={handleClose} item={selectedRoom} />
      <AddRoom
        open={openAddDrawer}
        onClose={handleCloseAddRoom}
        item={contract}
      />
      <DialogComponent
        open={openConfirmDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmStatusChange}
        title="Cambiar modo admin"
        children="¿Estás seguro de que deseas cambiar el modo admin?"
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Room;
