import { Box, Container, Grid, Typography } from '@mui/material';
import { Contract, ContractDetails, PlaceContent, Rooms } from '../../interfaces/contract';
import React, { useEffect, useState } from 'react';

import DataTable from '../share/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import StatisticCard from './StatisticCard';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

interface ContractExtended extends Contract {
  contractDetail?: ContractDetails[];
  placeContent?: PlaceContent[];
  rooms?: Rooms[];
}

const Dashboard = () => {
  const [contracts, setContracts] = useState<ContractExtended[]>([]);
  const [error, setError] = useState('');

  const getContractsStatistics = async () => {
    try {
      const response = await axiosInstance.get(endpoints.contractsWithDetails);
      setContracts(response.data);
    } catch (error) {
      setError('Error consultando información de contratos');
    }
  };

  useEffect(() => {
    getContractsStatistics();
  }, []);

  const totalDevices = contracts.reduce((acc, contract) => {
    if (contract.contractDetail && contract.contractDetail.length > 0) {
      return acc + contract.contractDetail.reduce((detailAcc, detail) => detailAcc + (detail.devices || 0), 0);
    }
    return acc;
  }, 0);

  const totalRooms = contracts.reduce((acc, contract) => acc + (contract.rooms ? contract.rooms.length : 0), 0);

  const comparisonData = [
    { name: 'Dispositivos', value: totalDevices },
    { name: 'Habitaciones', value: totalRooms },
  ];

  const statisticData = [
    {
      title: 'Contratos',
      value: `${contracts.length}`,
      data: contracts.map((contract, index) => ({
        name: `Contrato ${index + 1}`,
        value: contract.contractDetail && contract.contractDetail.length > 0 ? contract.contractDetail[0].devices : 0,
      })),
      color: '#fff',
      bgColor: '#82ca9d',
      chartType: 'line' as 'line',
    },
    {
      title: 'Total dispositivos disponibles',
      value: `${totalDevices}`,
      data: contracts.map((contract, index) => ({
        name: `Contrato ${index + 1}`,
        value: contract.contractDetail && contract.contractDetail.length > 0 ? contract.contractDetail[0].devices : 0,
      })),
      color: '#fff',
      bgColor: '#8884d8',
      chartType: 'bar' as 'bar',
    },
    {
      title: 'Dispositivos vs Habitaciones',
      value: `Dispositivos: ${totalDevices}, Habitaciones: ${totalRooms}`,
      data: comparisonData,
      color: '#fff',
      bgColor: '#ffab00',
      chartType: 'pie' as 'pie',
    },
  ];

  const contractColumns: GridColDef[] = [
    { field: 'contractName', headerName: 'Contrato' },
    { field: 'placeContentName', headerName: 'Hotel' },
    { field: 'devicesPermitted', headerName: 'Dispositivos permitidos', width: 200, align: 'center', headerAlign: 'center', type: 'number' },
    { field: 'devicesUsed', headerName: 'Dispositivos usados', width: 200, align: 'center', headerAlign: 'center', type: 'number' },
  ];

  const contractRows = contracts.map((contract, index) => {
    const devicesPermitted = contract.contractDetail?.reduce((sum, detail) => sum + detail.devices, 0) || 0;
    const devicesUsed = contract.rooms?.length || 0;
    const placeContentName = contract.placeContent?.map(pc => pc.name).join(', ') || 'N/A';

    return {
      id: index,
      contractName: contract.contractName,
      placeContentName,
      devicesPermitted,
      devicesUsed,
    };
  });

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {statisticData.map((stat, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <StatisticCard {...stat} />
          </Grid>
        ))}
        {error && (
          <Grid item xs={12}>
            <Typography color="error">{error}</Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ p: 2 }}>
            <DataTable 
              columns={contractColumns} 
              rows={contractRows} 
              pageSize={10} 
              title='Ocupación de dispositivos por contrato' 
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
