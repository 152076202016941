import {
  Box,
  Button,
  CircularProgress,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { Application } from '../../interfaces/application';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DataTable from '../share/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import axiosInstance from '../../config/axiosConfig';
import endpoints from '../../config/apiConfig';

const Applications: React.FC = () => {
  const [filter, setFilter] = useState('');
  const [applications, setApplications] = useState<Application[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const applicationColumns: GridColDef[] = [
    { field: 'identifier', headerName: 'ID', width: 200 },
    { field: 'name', headerName: 'Nombre' },
    { field: 'icon', headerName: 'Icono', width: 200, align: 'center' },
    { field: 'order', headerName: 'Orden', width: 150, align: 'center' },
    {
      field: 'active',
      headerName: 'Estado',
      width: 150,
      renderCell: (params: any) => (
        <Button>
          {params.row.active ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RemoveCircleOutlineIcon color="error" />
          )}
        </Button>
      ),
      align: 'center',
    },
    { field: 'metadata', headerName: 'Metadata' },
  ]; 

  const applicationRows = applications
    .filter((application) =>
      application.name.toLowerCase().includes(filter.toLowerCase()),
    )
    .map((application) => ({
      id: application._id,
      identifier: application.id,
      name: application.name,
      icon: application.icon,
      order: application.order,
      active: application.active,
      metadata: application.metadata,
    }));

  useEffect(() => {
    const fetchApplications = async () => {
      setIsLoading(true)
      try {
        const response = await axiosInstance.get(endpoints.applications);
        const sortedData = response.data.sort(
          (a: { order: number }, b: { order: number }) => a.order - b.order,
        );
        setApplications(sortedData);
      } catch (error) {
        console.error(`Error fetching applications`, error);
      } finally {
        setIsLoading(false)
      }
    };
    fetchApplications();
  }, []);

  return (
    <Box>
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : (
      <DataTable 
        columns={applicationColumns} 
        rows={applicationRows} 
        pageSize={10} 
        title='Aplicaciones' 
        buttonText='Nueva aplicación' />
      )}
    </Box>
  );
};

export default Applications;
